import React, { createRef, useEffect, useRef, useState } from "react";
import iconLikeDefault from "../style/img/icons/like_standby.svg";
import iconLikeActive from "../style/img/icons/like_active.svg";
import iconPlayer from "../style/img/icons/play.svg";
import annee2021 from "../style/img/elements/annee_2021.svg";
import defilerIconFR from "../style/img/elements/defiler_icon.svg";
import defilerIconEN from "../style/img/elements/defiler_icon-en.svg";
import imgHome from "../style/img/elements/img-home.png";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import textJson from "../style/text";
import ShareModal from "./ShareModal";
import SimpleParallax from "simple-parallax-js";
export default function HeaderHome({
  video_like,
  video_url,
  id,
  video_pic,
  created_at,
  origin,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [like, setLike] = useState(video_like);
  //const [origin, setOrigin] = useState(from);
  const [hadLiked, setHadLiked] = useState([]);
  const [JSONIMG, setJSONIMG] = useState(textJson[0]);
  const tongsRef = useRef(null);

  useEffect(() => {
    console.log("tongs -> ", tongsRef.current);
    new SimpleParallax(tongsRef.current, {
      delay: 0.6,
      scale: 1.5,
      orientation: "down",
      transition: "cubic-bezier(0,0,0,1)",
    });
  }, []);

  const hopLike = (id) => {
    if ($("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").removeClass("like-active");
      let like = Number($("#" + id + " > span").text()) - 1;
      $("#" + id + " > span").html(like);
    } else if (!$("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").removeClass("like-active");
      let like = Number($("#" + id + " > span").text()) + 1;
      $("#" + id + " > span").html(like);
    }
  };

  const sendLike = (id) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/like/${id}`)
      .then(() => {
        const alreadyLiked = JSON.parse(
          localStorage.getItem("liked-element-ax")
        );
        if (!alreadyLiked) {
          localStorage.setItem("liked-element-ax", JSON.stringify([id]));
        }
        if (alreadyLiked) {
          let newLiked = alreadyLiked;
          newLiked.push(id);
          localStorage.setItem("liked-element-ax", JSON.stringify(newLiked));
        }
      });
  };

  const sendUnlike = (id) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/dislike/${id}`)
      .then(() => {
        const alreadyLiked = JSON.parse(
          localStorage.getItem("liked-element-ax")
        );
        if (alreadyLiked) {
          let newLiked = alreadyLiked;
          newLiked.splice(newLiked.indexOf(id), 1);
          localStorage.setItem("liked-element-ax", JSON.stringify(newLiked));
        }
      });
  };

  const handleClick = (id, e) => {
    e.preventDefault();
    return;
    $("#" + id + "").addClass("disabled_like");

    //check if user already liked this element
    const alreadyLiked = JSON.parse(localStorage.getItem("liked-element-ax"));
    if (alreadyLiked) {
      if (alreadyLiked.includes(id)) {
        sendUnlike(id);

        $("#" + id + "").removeClass("like-active");
      } else {
        sendLike(id);
        $("#" + id + "").addClass("like-active");
      }
    }

    hopLike(id);
    setTimeout(() => {
      $("#" + id + "").removeClass("disabled_like");
    }, 1000);
  };

  const handleOpen = (url) => {
    localStorage.setItem("share-modal-ax", url);
    setIsOpen(true);
  };

  // componentDidMount() {
  //   const tongs = document.querySelectorAll("#img-home-tongs");
  //   new SimpleParallax(tongs, {
  //     delay: 0.6,
  //   });
  // }

  let JSONTEXT = { "": "" };
  const language = localStorage.getItem("av-2022-lang");
  if (language == "fr") {
    JSONTEXT = textJson[0].fr;
  } else {
    JSONTEXT = textJson[0].en;
  }
  const url = "/player/" + id;
  return (
    <div className="relative">
      <ShareModal
        isOpen={isOpen}
        path={url.replace("/player/", "")}
        onClose={() => setIsOpen(false)}
      />
      <div id="particles-js"></div>
      <header className="header-home">
        {/* <img
            className="rectangle-header rectangle-gauche"
            src={rectangleGauche}
            alt="unknown"
          /> */}
        {/* <img
            className="rectangle-header rectangle-droite"
            src={rectangleDroit}
            alt="unknown"
          /> */}

        <img
          className="rectangle-header defiler"
          src={language === "fr" ? defilerIconFR : defilerIconEN}
          alt="unknown"
        />

        <img className="rectangle-header grue" src={imgHome} alt="img-home-1" />

        <div className="d-flex align-items-center justify-content-center header-container flex-md-row flex-column container">
          <div className="col-md-6 col-sm-12 mt-md-0 mt-5 p-5 text-center ">
            {/* <div className="container-triple-h">
              <h5 style={{ "font-size": "15px" }}>{JSONTEXT.main.subTitle1}</h5>
              <h4 style={{ "font-size": "56px" }}>{JSONTEXT.main.subTitle2}</h4>
              <h4 style={{ "font-size": "30px" }}>{JSONTEXT.main.subTitle3}</h4>
            </div> */}
            <img
              src={JSONTEXT.main.logo}
              alt="year-img"
              className="m-auto !mb-10"
            />
            <div id="annee-header">
              <img
                src={JSONIMG.images.challenge}
                alt="year-img"
                className="m-auto"
              />
            </div>
            <p
              style={{ lineHeight: "2" }}
              dangerouslySetInnerHTML={{ __html: JSONTEXT?.main?.description }}
            />
            <a
              href="#"
              className="w-full text-white"
              style={{ textDecoration: "none" }}
            >
              <button className="font-bebas mt-4 rounded-full border border-white bg-gradient-to-r from-[#ff7166] to-[#ff7166] px-5 py-2 text-[20px] font-bold uppercase tracking-widest text-white transition-all hover:to-[#ffb456] focus:outline-none xl:w-[280px]">
                {JSONTEXT.main.button}
              </button>
            </a>
            {/* <p>
              <div className="btn-axione-animation hover:bg-[#ffb456]">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#ModalUpload"
                  className="nav-link flex items-center justify-center rounded-full !text-xl font-bold"
                >
                  {JSONTEXT.main.button}
                </a>
                <div></div>
              </div>
            </p> */}
          </div>
          <div className="col-md-6 col-sm-12 mb-4 md:mb-0">
            <div className="video-container" id="top1-video">
              <div>
                {(() => {
                  if (
                    url.split(".")[1] === "mp4" ||
                    url.split(".")[1] === "webm"
                  ) {
                    return (
                      <a className="player-icon" href={url}>
                        <img src={iconPlayer} alt="unknown" />
                      </a>
                    );
                  }
                })()}
                <div className="action-barre d-flex justify-content-between">
                  <a
                    onClick={(e) => handleClick(id, e)}
                    href="#"
                    className="like-button text-decoration-none"
                    id={id}
                  >
                    <div className="like-animation">
                      <img src={iconLikeActive} alt="unknown" />
                      <img src={iconLikeDefault} alt="unknown" />
                    </div>
                    <span>{like}</span>
                  </a>
                  <img
                    onClick={() => {
                      handleOpen(process.env.REACT_APP_FRONT_URL + url);
                    }}
                    style={{
                      width: "28px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                    src={JSONIMG.picto.share.img.path}
                  />
                </div>
                <a href={url}>
                  <div></div>
                  <img src={video_pic} alt="unknown" />
                </a>
              </div>
              <h1>#1 {JSONTEXT.main.trend}</h1>
              <p className="mt-1 text-white">{origin}</p>
            </div>
          </div>
        </div>

        <img
          id="img-home-tongs"
          src={JSONIMG.images.tongs.img.path}
          alt={JSONIMG.images.tongs.img.alt}
          className="rectangle-header tongs"
          ref={tongsRef}
        />
      </header>
    </div>
  );
}
