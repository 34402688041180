export default function SocialMediaFigure({
  alt,
  src,
  children,
  onClickFunction,
}) {
  return (
    <figure>
      <img alt={alt} src={src} onClick={() => onClickFunction()} />
      <figcaption>{children}</figcaption>
    </figure>
  );
}
