const text = [
  {
    images: {
      sunGlasses: require("../style/svg/images/sun-glasses.svg").default,
      tongs: {
        img: {
          path: require("../style/svg/images/tongs.png"),
          alt: "tongs",
        },
      },
      womenComputer: {
        img: {
          path: require("../style/svg/images/woman-computer.svg").default,
          alt: "womenComputer",
        },
      },
      challenge: require("../style/svg/images/challenge-photo.svg").default,
      ete: require("../style/svg/images/animons-ete.svg").default,
    },
    picto: {
      share: {
        img: {
          path: require("../style/svg/random-pictos/share.svg").default,
          alt: "share",
        },
      },
    },
    share: {
      server: "http://localhost:3000/player/",
      facebook: "https://www.facebook.com/sharer/sharer.php?u=",
      mail: "mailto:?body=",
      linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=",
      instagram: "https://www.instagram.com/axione_/",
    },
    fr: {
      nav: {
        photos: "les photos",
        rules: "comment participer",
        button: "participer",
        languages: {
          fr: "FR",
          en: "EN",
        },
      },
      main: {
        name: "Été 2022",
        title: "Photo challenge",
        logo: "/svg/axione/animons-ete-circle-fr.svg",
        subTitle1: "Animons",
        subTitle2: "l'été",
        subTitle3: "2022",
        description:
          "<div style='letter-spacing: 1px;'>C’est les vacances !</br>  1, 2, 3 partez ! Sur une île déserte ou dans votre jardin... </br> Et photographiez !<br/>Animez le monde cet été partout où vous allez.</div>",
        button: "Concours clôturé",
        trend: "des tendances",
        scroll: "Défiler",
      },
      body: {
        content: "Aucun contenu",
        button: "Poster votre photo",
      },
      logo: {
        white: {
          path: "/svg/axione/logo/white.svg",
          alt: "axione-white",
        },
        colored: {
          path: "/svg/axione/logo/colored.svg",
          alt: "axione-colored",
        },
        animons: {
          path: "/svg/axione/animons-le-monde.svg",
          alt: "axione-animons",
        },
      },

      trends: {
        trend1: "Tendance 1",
        trend2: "Tendance 2",
        trend3: "Tendance 3",
      },
      rules: {
        title1: "Comment participer",
        description:
          "Le challenge est simple, prendre en photo un objet ou quelque chose qui évoque l’entreprise Axione, sur votre lieu de vacances. Les photos ayant obtenu le plus de votes gagnent un cadeau !",
      },
      participate: {
        title: "Comment participer ?",
        step1: {
          text: "Prenez une photo avec un élément Axione,",
          subtext:
            "comme un goodies logotypé ou bien tout simplement en écrivant Axione<br/>sur une feuille, dans un lieu évoquant les vacances.",
          img: {
            path: "/svg/steps/step1/1.svg",
            alt: "step1",
          },
          picto: {
            path: "/svg/steps/step1/picto.svg",
            alt: "picto",
          },
        },
        step2: {
          text: "<span class='underline'>Postez votre photo<br/>d’ici le  mercredi 31 août 2022</span>",
          img: {
            path: "/svg/steps/step2/2.svg",
            alt: "step2",
          },
          picto: {
            path: "/svg/steps/step2/picto.svg",
            alt: "picto",
          },
        },
        step3: {
          text: "Incitez vos collègues et votre entourage<br/>à voter pour vous.",
          img: {
            path: "/svg/steps/step3/3.svg",
            alt: "step3",
          },
          picto: {
            path: "/svg/steps/step3/picto.svg",
            alt: "picto",
          },
        },
        help: "Besoin d'aide ?",
        button: "Modalités du challenge",
      },
      footer: {
        button: "Participer",
        legalNotices: "Mentions légales",
        privacy: "Politique de confidentialité",
        copyright: "© agenceRJS 2022-2023",
      },
      social: {
        follow: "Suivez-nous",
        share: "Partagez",
        return: "Retour",
        cross: {
          img: {
            path: require("../style/svg/random-pictos/cross.svg").default,
            alt: "cross",
          },
        },
        instagram: {
          img: {
            path: "/svg/socials/instagram.svg",
            name: "Instagram",
            alt: "Instagram",
          },
          url: "https://www.instagram.com/axione_/",
        },
        smallInsta: {
          img: {
            path: require("../style/svg/socials/small-insta.svg").default,
            name: "Instagram",
            alt: "small-instagram",
          },
          url: "https://www.instagram.com/axione_/",
        },
        facebook: {
          img: {
            path: require("../style/svg/socials/facebook.svg").default,
            name: "Facebook",
            alt: "Axione-Facebook",
          },
          url: "https://www.instagram.com/axione_/",
        },
        twitter: {
          img: {
            path: "/svg/socials/twitter.svg",
            name: "Twitter",
            alt: "Axione-Twitter",
          },
          url: "https://twitter.com/AxioneOfficiel",
        },
        mail: {
          img: {
            path: "/svg/socials/mail.svg",
            name: "Mail",
            alt: "Axione-Mail",
          },
          url: "https://mail.com/eté2022",
        },
        youtube: {
          img: {
            path: "/svg/socials/youtube.svg",
            name: "Youtube",
            alt: "axione-Youtube",
          },
          url: "https://www.youtube.com/channel/UCuXvVCpkXpcoW3PYSMv-joA",
        },
        linkedin: {
          img: {
            path: "/svg/socials/linkedin.svg",
            name: "Linkedin",
            alt: "Axione-Linkedin",
          },
          url: "https://www.linkedin.com/company/axione/",
        },
      },
      error404: {
        description: "La page que vous cherchez <br/> semble introuvable.",
        button: "Retour a la page d'accueil",
        img: {
          path: "/svg/404/404.svg",
          alt: "404",
        },
      },
      upload: {
        title: "",
        description1: `<strong>Animez le monde partout où vous allez cet été !</strong>
        Que vos vacances soient faites de repos ou d’aventure, que vous séjourniez au coin de la rue ou au bout du monde, que vous soyez plutôt mer ou plutôt montagne… </br>
        Embarquez Axione avec vous !`,
        description2: "",
        help: "",
        data: "J’accepte que ma photo puisse être utilisée dans des communications internes et/ou externes de l’entreprise.",
        rules:
          "<br/> Et si je suis présent sur ma photo, j’autorise l’exploitation de mes droits à l’image.<br/> <i> Les éventuelles autres personnes présentes sur votre photo ne doivent pas être identifiables.</i>",
        rules2:
          "Je respecte les règles de publication de contenu définies dans les modalités du challenge.",
        rules3: "J’atteste être collaborateur de l’entreprise Axione.",
        button: "Envoyer",
        back: "Retour",
        dragndrop: "Glissez - déposez vos photos ici",
        maxsize: "Taille maximale : 5Mo",
        upload: "ou cliquez pour sélectionner votre fichier",
        asterisk: "mention obligatoire",
        success: {
          title: " Votre téléchargement est terminé !",
          button: "Terminer",
        },
        error: {
          size: "Le fichier est trop volumineux",
          type: "Ce type de fichier n'est pas autorisé",
        },
        placeholder: {
          name: "Nom*",
          firstname: "Prénom*",
          email: "Email*",
          city: "Ville de la photo*",
          country: "Pays de la photo*",
        },
        img: {
          path: "/svg/random-pictos/upload.svg",
          alt: "upload",
        },
      },
    },
    en: {
      nav: {
        photos: "pictures",
        rules: "how to participate",
        button: "participer",
        languages: {
          fr: "FR",
          en: "EN",
        },
      },
      main: {
        name: "Summer 2022",
        title: "Photo Challenge",
        logo: "/svg/axione/animons-ete-circle-en.svg",
        subTitle1: "Animons",
        subTitle2: "l'été",
        subTitle3: "2022",
        description:
          "<div style='letter-spacing: 1px;'>It's the holidays season!</br> Time to get away! Perhaps to some desert island, or right in your back garden... </br>  And time to take some holiday snaps! Bring ideas to life in pictures this summer.</br>Great prizes to be won for the 5 most-liked photos.</div>",
        button: "competition closed",
        trend: "trends",
        scroll: "Défiler",
      },
      body: {
        content: "No content",
        button: "Post your photo",
      },
      logo: {
        white: {
          path: "/svg/axione/logo/white.svg",
          alt: "axione-white",
        },
        colored: {
          path: "/svg/axione/logo/colored.svg",
          alt: "axione-colored",
        },
        animons: {
          path: "/svg/axione/animons-le-monde.svg",
          alt: "axione-animons",
        },
      },

      trends: {
        trend1: "Tendance 1",
        trend2: "Tendance 2",
        trend3: "Tendance 3",
      },
      rules: {
        title1: "Règlement",
        title2: "Du jeu",
        description:
          "Le challenge est simple, prendre en photo un objet ou quelque chose qui évoque l’entreprise Axione, sur votre lieu de vacances. Les photos ayant obtenu le plus de votes gagnent un cadeau !",
      },

      participate: {
        title: "How to participate ?",
        step1: {
          text: "Take a photo related to Axione",
          subtext:
            " in some way, e.g. holding something with the Axione logo,<br/> or simply brandishing “Axione” written on a sheet of paper in your place of stay.",
          img: {
            path: "/svg/steps/step1/1.svg",
            alt: "step1",
          },
          picto: {
            path: "/svg/steps/step1/picto.svg",
            alt: "picto",
          },
        },
        step2: {
          text: "<span class='underline'>Post your photo<br/>by Wednesday, August 31, 2022</span>",
          img: {
            path: "/svg/steps/step2/2.svg",
            alt: "step2",
          },
          picto: {
            path: "/svg/steps/step2/picto.svg",
            alt: "picto",
          },
        },
        step3: {
          text: "Encourage your friends and colleagues to vote for you.<br/>If your photo is one of the 5 most liked, you win a prize to prolong the holiday spirit!",
          img: {
            path: "/svg/steps/step3/3.svg",
            alt: "step3",
          },
          picto: {
            path: "/svg/steps/step3/picto.svg",
            alt: "picto",
          },
        },
        help: "Besoin d'aide ?",
        button: "Terms of the challenge",
      },
      footer: {
        button: "Participer",
        legalNotices: "Legal Notice",
        privacy: "Confidentiality policy",
        copyright: "© agenceRJS 2022-2023",
      },
      social: {
        follow: "Follow us",
        share: "Share",
        return: "Back",
        cross: {
          img: {
            path: require("../style/svg/random-pictos/cross.svg").default,
            alt: "cross",
          },
        },
        instagram: {
          img: {
            path: "/svg/socials/instagram.svg",
            name: "Instagram",
            alt: "Instagram",
          },
          url: "https://www.instagram.com/axione_/",
        },
        smallInsta: {
          img: {
            path: require("../style/svg/socials/small-insta.svg").default,
            name: "Instagram",
            alt: "small-instagram",
          },
          url: "https://www.instagram.com/axione_/",
        },
        facebook: {
          img: {
            path: require("../style/svg/socials/facebook.svg").default,
            name: "Facebook",
            alt: "Axione-Facebook",
          },
          url: "https://instagram.com/axione_?igshid=1tglwt0404291",
        },
        twitter: {
          img: {
            path: "/svg/socials/twitter.svg",
            name: "Twitter",
            alt: "Axione-Twitter",
          },
          url: "https://twitter.com/AxioneOfficiel",
        },
        mail: {
          img: {
            path: "/svg/socials/mail.svg",
            name: "Mail",
            alt: "Axione-Mail",
          },
          url: "https://mail.com/eté2022",
        },
        youtube: {
          img: {
            path: "/svg/socials/youtube.svg",
            name: "Youtube",
            alt: "axione-Youtube",
          },
          url: "https://www.youtube.com/channel/UCuXvVCpkXpcoW3PYSMv-joA",
        },
        linkedin: {
          img: {
            path: "/svg/socials/linkedin.svg",
            name: "Linkedin",
            alt: "Axione-Linkedin",
          },
          url: "https://www.linkedin.com/company/axione/",
        },
      },
      error404: {
        description: "La page que you cherchez <br/> semble introuvable.",
        button: "Retour a la page d'accueil",
        img: {
          path: "/svg/404/404.svg",
          alt: "404",
        },
      },
      upload: {
        title: "",
        description1: `<strong>Bring ideas to life wherever you go this summer!</strong> </br>
        Whether your holidays are for relaxing or adventure, whether you are just around the corner or on the other side of the world, on a beach or in the mountains…</br>Take Axione with you!`,
        description2: "",
        help: "",
        data: "If I am present in the photo, I confirm that I authorise my rights to the image. Any other persons present in your photo must not be identifiable.",
        rules:
          "I confirm, if I am present in my photo, authorize my image rights. Any other people in your photo should not be recognizable.",
        rules2:
          "I respect the rules for publishing content defined in the terms of the challenge.",
        rules3: "I certify that I am an Axione employee.",
        button: "Send",
        back: "Back",
        dragndrop: "Drag & drop your pictures here",
        maxsize: "Maximal size : 5Mo",
        upload: "or click here to upload",
        asterisk: "required",
        success: {
          title: "Your photo has been sent!",
          button: "Return",
        },
        error: {
          size: "Your photo is too big",
          type: "Your photo is not in the correct format",
        },
        placeholder: {
          name: "Last name*",
          firstname: "First name*",
          email: "Email*",
          city: "Town of the photo*",
          country: "Country of the photo*",
        },
        img: {
          path: "/svg/random-pictos/upload.svg",
          alt: "upload",
        },
      },
    },
  },
];

export default text;
