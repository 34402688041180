import React, { Component } from "react";
import videoCodir from "../style/video/interview.mp4";

export default class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex justify-content-center video-player align-items-center container">
        <div className="">
          <video controls muted autoPlay>
            <source src={videoCodir} type="video/mp4"></source>
          </video>
          <div className="col-12 title-video">
            <h4>Interview CODIR</h4>
          </div>
        </div>
      </div>
    );
  }
}
