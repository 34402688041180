import React, { Component, useContext } from "react";
import logo from "../style/img/logos/logodark.svg";
import { JsonContext } from "../context/json.context";

export default function NavbarVideo() {
  const { JSON } = useContext(JsonContext);

  return (
    <>
      {JSON && (
        <nav className="navbar navbar-video navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="/">
            <img src={logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse navbar-axione-video d-lg-flex justify-content-lg-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/#videos">
                  {JSON.nav.photos}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#participer">
                  {JSON.nav.rules}
                </a>
              </li>
              {/* <li className="nav-item">
              <div className="btn-upload-nav" id="upload-btn-player">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#ModalUpload"
                  className="nav-link"
                >
                  postez vos vœux
                </a>
                <div></div>
              </div>
            </li> */}
            </ul>
          </div>
        </nav>
      )}
    </>
  );
}
