/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ItemCurrentScroll from "./ItemCurrentScroll";
import HeaderHome from "./HeaderHome";
import Interview from "./Interview";
import Participer from "./Participer";
import Footer from "./Footer";
import logodark from "../style/img/logos/logodark.svg";
import novideoicon from "../style/img/elements/Cloud.svg";
import $ from "jquery";
import moment from "moment";
import Pagination from "./PaginationWrap";

import iconLikeDefault from "../style/img/icons/like_standby.svg";
import iconLikeActive from "../style/img/icons/like_active.svg";
import iconPlayer from "../style/img/icons/play.svg";
import iconArrow from "../style/img/icons/arrow.svg";
import textJson from "../style/text";
import ShareModal from "./ShareModal";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      videos: [],
      FirstVideo: [],
      SecondVideo: [],
      TreeVideo: [],
      FourthVideo: [],
      loading: true,
      noVideo: false,

      selectedPostUrl: "",
      shareModalIsOpen: false,
    };

    const queryString = window.location.search;
    const postIdFromUrl = queryString.replace("?", "").split("&")[0];

    if (postIdFromUrl && postIdFromUrl.length) {
      window.location.href = `/player/${postIdFromUrl}`;
    }
  }

  handlePageChange(pageNumber) {
    //    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  componentDidMount() {}

  hopLike(id) {
    if ($("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").removeClass("like-active");
      let like = Number($("#" + id + " > span").text()) - 1;
      $("#" + id + " > span").html(like);
    } else if (!$("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").addClass("like-active");
      let like = Number($("#" + id + " > span").text()) + 1;
      $("#" + id + " > span").html(like);
    }
  }

  sendLike(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/like/${id}`)
      .then(() => {
        const alreadyLiked = JSON.parse(
          localStorage.getItem("liked-element-ax")
        );
        if (!alreadyLiked) {
          localStorage.setItem("liked-element-ax", JSON.stringify([id]));
        }
        if (alreadyLiked) {
          let newLiked = alreadyLiked;
          newLiked.push(id);
          localStorage.setItem("liked-element-ax", JSON.stringify(newLiked));
        }
      });
  }
  sendUnlike(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/dislike/${id}`)
      .then(() => {
        const alreadyLiked = JSON.parse(
          localStorage.getItem("liked-element-ax")
        );
        if (alreadyLiked) {
          let newLiked = alreadyLiked;
          newLiked.splice(newLiked.indexOf(id), 1);
          localStorage.setItem("liked-element-ax", JSON.stringify(newLiked));
        }
      });
  }

  handleClick(id, e) {
    e.preventDefault();
    return;
    $("#" + id + "").addClass("disabled_like");

    //check if user already liked this element
    const alreadyLiked = JSON.parse(localStorage.getItem("liked-element-ax"));
    if (alreadyLiked) {
      if (alreadyLiked.includes(id)) {
        this.sendUnlike(id);

        $("#" + id + "").removeClass("like-active");
      } else {
        this.sendLike(id);
        $("#" + id + "").addClass("like-active");
      }
    }

    this.hopLike(id);
    setTimeout(() => {
      $("#" + id + "").removeClass("disabled_like");
    }, 1000);
  }

  //get data from API
  componentDidMount() {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/element`).then((res) => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        if (res.data.message != "No such videos in data table.") {
          this.setState({
            loading: false,
            FirstVideo: res.data[0],
            SecondVideo: res.data[1],
            ThirdVideo: res.data[2],
            FourthVideo: res.data[3],
          });

          //remove 4th videos from array
          res.data.splice(res.data[0], 1);
          res.data.splice(res.data[1], 1);
          res.data.splice(res.data[2], 1);
          res.data.splice(res.data[3], 1);

          this.setState({ videos: res.data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  toMinute(secondes) {
    const min = secondes / 60;
    return min;
  }

  openShareModal(id, event) {
    event.preventDefault();
    this.setState({
      selectedPostUrl: id,
      shareModalIsOpen: true,
    });
  }

  render() {
    let JSON = "";
    let JSONIMG = textJson[0];
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }
    if (this.state.loading === true) {
      return (
        <div className="loader-axione d-flex justify-content-center align-items-center flex-column">
          <a href="#" data-toggle="modal" data-target="#ModalUpload">
            <img src={logodark} className="logo-loading" alt="axione-logo" />
          </a>
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    {
      /* -----> If there is no video in DB <----- */
    }
    if (typeof this.state.FirstVideo == "undefined") {
      return (
        <div className="nofoundvideo d-flex justify-content-center align-items-center">
          <p>
            {JSON.body.content}

            <br />
            <a href="#" data-toggle="modal" data-target="#ModalUpload">
              {JSON.body.button}
            </a>
          </p>
        </div>
      );
    }

    {
      /* -----> If there is only ONE video <----- */
    }
    if (typeof this.state.SecondVideo == "undefined") {
      return (
        <>
          <ShareModal
            isOpen={this.state.shareModalIsOpen}
            path={this.state.selectedPostUrl}
            onClose={() => this.setState({ shareModalIsOpen: false })}
          />
          <div className="">
            <ItemCurrentScroll></ItemCurrentScroll>
            <HeaderHome
              id={this.state.FirstVideo.id}
              video_pic={`${process.env.REACT_APP_API_URL}/element/${this.state.FirstVideo.url}`}
              video_like={this.state.FirstVideo.likes}
              video_url={this.state.FirstVideo.url}
              origin={
                this.state.FirstVideo.city +
                " - " +
                this.state.FirstVideo.country
              }
            ></HeaderHome>
            <main>
              <section id="videos">
                {/* ---> [START] CONTAINER VIDEO TOP <--- */}
                <div className="d-flex container flex-wrap">
                  {/* ---> TOP 2 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top2">
                    <div className="nofoundvideo d-flex justify-content-center align-items-center">
                      <p>
                        {JSON.body.content}

                        <br />
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#ModalUpload"
                        >
                          {JSON.body.button}
                        </a>
                      </p>
                    </div>
                    <h1>#2 {JSON.main.trend}</h1>
                  </div>
                  {/* ---> TOP 3 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top3">
                    <div className="nofoundvideo d-flex justify-content-center align-items-center">
                      <p>
                        {JSON.body.content}

                        <br />
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#ModalUpload"
                        >
                          {JSON.body.button}
                        </a>
                      </p>
                    </div>
                    <h1>#3 {JSON.main.trend}</h1>
                  </div>
                  {/* ---> TOP 4 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top4">
                    <div className="nofoundvideo d-flex justify-content-center align-items-center">
                      <p>
                        {JSON.body.content}

                        <br />
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#ModalUpload"
                        >
                          {JSON.body.button}
                        </a>
                      </p>
                    </div>
                    <h1>#4 {JSON.main.trend}</h1>
                  </div>
                </div>
              </section>

              <Participer></Participer>
            </main>
            <Footer></Footer>
          </div>
        </>
      );
    }

    {
      /* -----> If there is only TWO video <----- */
    }
    if (typeof this.state.ThirdVideo == "undefined") {
      return (
        <>
          <ShareModal
            isOpen={this.state.shareModalIsOpen}
            path={this.state.selectedPostUrl}
            onClose={() => this.setState({ shareModalIsOpen: false })}
          />
          <div className="">
            <ItemCurrentScroll></ItemCurrentScroll>
            <HeaderHome
              id={this.state.FirstVideo.id}
              video_pic={`${process.env.REACT_APP_API_URL}/element/${this.state.FirstVideo.url}`}
              video_like={this.state.FirstVideo.likes}
              video_url={this.state.FirstVideo.url}
              origin={
                this.state.FirstVideo.city +
                " - " +
                this.state.FirstVideo.country
              }
            ></HeaderHome>
            <main>
              <section id="videos">
                {/* ---> [START] CONTAINER VIDEO TOP <--- */}
                <div className="d-flex container flex-wrap">
                  {/* ---> TOP 2 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top2">
                    <div>
                      {(() => {
                        if (
                          this.state.SecondVideo.url.split(".")[1] == "mp4" ||
                          this.state.SecondVideo.url.split(".")[1] == "webm"
                        ) {
                          return (
                            <Link
                              className="player-icon"
                              to={`/player/${this.state.SecondVideo.url}`}
                            >
                              <img src={iconPlayer} />
                            </Link>
                          );
                        }
                      })()}
                      <div className="action-barre d-flex justify-content-between">
                        <a
                          onClick={(e) =>
                            this.handleClick(this.state.SecondVideo.id, e)
                          }
                          href="#"
                          className="like-button text-decoration-none"
                          id={this.state.SecondVideo.id}
                        >
                          <div className="like-animation">
                            <img src={iconLikeActive} />
                            <img src={iconLikeDefault} />
                          </div>
                          <span>{this.state.SecondVideo.likes}</span>
                        </a>
                        <a
                          href="#"
                          onClick={(e) =>
                            this.openShareModal(this.state.SecondVideo.id, e)
                          }
                          className="share-button text-decoration-none"
                        >
                          <img src={JSONIMG.picto.share.img.path} />
                        </a>
                      </div>
                      <Link to={`/player/${this.state.SecondVideo.id}`}>
                        <div></div>
                        <img
                          src={
                            `${process.env.REACT_APP_API_URL}/element/` +
                            this.state.SecondVideo.url
                          }
                        />
                      </Link>
                    </div>
                    <h1>#2 {JSON.main.trend}</h1>
                    <p className="mt-1">
                      {this.state.SecondVideo.city} -{" "}
                      {this.state.SecondVideo.country}
                    </p>
                  </div>
                  {/* ---> TOP 3 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top3">
                    <div className="nofoundvideo d-flex justify-content-center align-items-center">
                      <p>
                        {JSON.body.content}

                        <br />
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#ModalUpload"
                        >
                          {JSON.body.button}
                        </a>
                      </p>
                    </div>
                    <h1>#3 {JSON.main.trend}</h1>
                  </div>
                  {/* ---> TOP 4 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top4">
                    <div className="nofoundvideo d-flex justify-content-center align-items-center">
                      <p>
                        {JSON.body.content}
                        <br />
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#ModalUpload"
                        >
                          {JSON.body.button}
                        </a>
                      </p>
                    </div>
                    <h1>#4 {JSON.main.trend}</h1>
                  </div>
                </div>
              </section>
              {/* <Interview></Interview> */}
              <Participer></Participer>
            </main>
            <Footer></Footer>
          </div>
        </>
      );
    }

    {
      /* -----> If there is only THREE video <----- */
    }
    if (typeof this.state.FourthVideo == "undefined") {
      return (
        <>
          <ShareModal
            isOpen={this.state.shareModalIsOpen}
            path={this.state.selectedPostUrl}
            onClose={() => this.setState({ shareModalIsOpen: false })}
          />
          <div className="">
            <ItemCurrentScroll></ItemCurrentScroll>
            <HeaderHome
              id={this.state.FirstVideo.id}
              video_pic={`${process.env.REACT_APP_API_URL}/element/${this.state.FirstVideo.url}`}
              video_like={this.state.FirstVideo.likes}
              video_url={this.state.FirstVideo.url}
              origin={
                this.state.FirstVideo.city +
                " - " +
                this.state.FirstVideo.country
              }
            ></HeaderHome>
            <main>
              <section id="videos">
                {/* ---> [START] CONTAINER VIDEO TOP <--- */}
                <div className="d-flex container flex-wrap">
                  {/* ---> TOP 2 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top2">
                    <div>
                      {(() => {
                        if (
                          this.state.SecondVideo.url.split(".")[1] == "mp4" ||
                          this.state.SecondVideo.url.split(".")[1] == "webm"
                        ) {
                          return (
                            <Link
                              className="player-icon"
                              to={`/player/${this.state.SecondVideo.id}`}
                            >
                              <img src={iconPlayer} />
                            </Link>
                          );
                        }
                      })()}
                      <div className="action-barre d-flex justify-content-between">
                        <a
                          onClick={(e) =>
                            this.handleClick(this.state.SecondVideo.id, e)
                          }
                          href="#"
                          className="like-button text-decoration-none"
                          id={this.state.SecondVideo.id}
                        >
                          <div className="like-animation">
                            <img src={iconLikeActive} />
                            <img src={iconLikeDefault} />
                          </div>
                          <span>{this.state.SecondVideo.likes}</span>
                        </a>
                        {/* onclick set isOpen to true */}

                        <img
                          src={JSONIMG.picto.share.img.path}
                          alt={JSONIMG.picto.share.img.alt}
                        />
                      </div>
                      <Link to={`/player/${this.state.SecondVideo.id}`}>
                        <div></div>
                        <img
                          src={
                            `${process.env.REACT_APP_API_URL}/element/` +
                            this.state.SecondVideo.url
                          }
                        />
                      </Link>
                    </div>
                    <h1>#2 {JSON.main.trend}</h1>
                    <p className="mt-1">
                      {this.state.SecondVideo.city} -{" "}
                      {this.state.SecondVideo.country}
                    </p>
                  </div>
                  {/* ---> TOP 3 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top3">
                    <div>
                      {(() => {
                        if (
                          this.state.ThirdVideo.url.split(".")[1] == "mp4" ||
                          this.state.ThirdVideo.url.split(".")[1] == "webm"
                        ) {
                          return (
                            <Link
                              className="player-icon"
                              to={`/player/${this.state.ThirdVideo.id}`}
                            >
                              <img src={iconPlayer} />
                            </Link>
                          );
                        }
                      })()}
                      <div className="action-barre d-flex justify-content-between">
                        <a
                          onClick={(e) =>
                            this.handleClick(this.state.ThirdVideo.id, e)
                          }
                          href="#"
                          className="like-button text-decoration-none"
                          id={this.state.ThirdVideo.id}
                        >
                          <div className="like-animation">
                            <img src={iconLikeActive} />
                            <img src={iconLikeDefault} />
                          </div>
                          <span>{this.state.ThirdVideo.likes}</span>
                        </a>
                        <a
                          href="#"
                          onClick={(e) =>
                            this.openShareModal(this.state.ThirdVideo.id, e)
                          }
                          className="share-button text-decoration-none"
                        >
                          <img src={JSONIMG.picto.share.img.path} />
                        </a>
                      </div>
                      <Link to={`/player/${this.state.ThirdVideo.id}`}>
                        <div></div>
                        <img
                          src={
                            `${process.env.REACT_APP_API_URL}/element/` +
                            this.state.ThirdVideo.url
                          }
                        />
                      </Link>
                    </div>
                    <h1>#3 {JSON.main.trend}</h1>
                    <p className="mt-1">
                      {this.state.ThirdVideo.city} -{" "}
                      {this.state.ThirdVideo.country}
                    </p>
                  </div>
                  {/* ---> TOP 4 <--- */}
                  <div className="video-container col-sm-12 col-lg-4" id="top4">
                    <div className="nofoundvideo d-flex justify-content-center align-items-center">
                      <p>
                        {JSON.body.content}
                        <br />
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#ModalUpload"
                        >
                          {JSON.body.button}
                        </a>
                      </p>
                    </div>
                    <h1>#4 {JSON.main.trend}</h1>
                  </div>
                </div>
              </section>
              {/* <Interview></Interview> */}
              <Participer></Participer>
            </main>
            <Footer></Footer>
          </div>
        </>
      );
    }
    return (
      <>
        <ShareModal
          isOpen={this.state.shareModalIsOpen}
          path={this.state.selectedPostUrl}
          onClose={() => this.setState({ shareModalIsOpen: false })}
        />
        <div className="">
          <ItemCurrentScroll></ItemCurrentScroll>
          <HeaderHome
            id={this.state.FirstVideo.id}
            video_pic={`${process.env.REACT_APP_API_URL}/element/${this.state.FirstVideo.url}`}
            video_like={this.state.FirstVideo.likes}
            video_url={this.state.FirstVideo.url}
            origin={
              this.state.FirstVideo.city + " - " + this.state.FirstVideo.country
            }
          ></HeaderHome>
          <main>
            <section id="videos">
              {/* ---> [START] CONTAINER VIDEO TOP <--- */}
              <div className="d-flex container flex-wrap">
                {/* ---> TOP 2 <--- */}
                <div className="video-container col-sm-12 col-lg-4" id="top2">
                  <div>
                    {(() => {
                      if (
                        this.state.SecondVideo.url.split(".")[1] == "mp4" ||
                        this.state.SecondVideo.url.split(".")[1] == "webm"
                      ) {
                        return (
                          <Link
                            className="player-icon"
                            to={`/player/${this.state.SecondVideo.id}`}
                          >
                            <img src={iconPlayer} />
                          </Link>
                        );
                      }
                    })()}
                    <div className="action-barre d-flex justify-content-between">
                      <a
                        onClick={(e) =>
                          this.handleClick(this.state.SecondVideo.id, e)
                        }
                        href="#"
                        className="like-button text-decoration-none"
                        id={this.state.SecondVideo.id}
                      >
                        <div className="like-animation">
                          <img src={iconLikeActive} />
                          <img src={iconLikeDefault} />
                        </div>
                        <span>{this.state.SecondVideo.likes}</span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) =>
                          this.openShareModal(this.state.SecondVideo.id, e)
                        }
                        className="share-button text-decoration-none"
                      >
                        <img src={JSONIMG.picto.share.img.path} />
                      </a>
                    </div>
                    <Link to={`/player/${this.state.SecondVideo.id}`}>
                      <div></div>
                      <img
                        src={
                          `${process.env.REACT_APP_API_URL}/element/` +
                          this.state.SecondVideo.url
                        }
                      />
                    </Link>
                  </div>
                  <h1>#2 {JSON.main.trend}</h1>
                  <p className="mt-1">
                    {this.state.SecondVideo.city} -{" "}
                    {this.state.SecondVideo.country}
                  </p>
                </div>
                {/* ---> TOP 3 <--- */}
                <div className="video-container col-sm-12 col-lg-4" id="top3">
                  <div>
                    {(() => {
                      if (
                        this.state.ThirdVideo.url.split(".")[1] == "mp4" ||
                        this.state.ThirdVideo.url.split(".")[1] == "webm"
                      ) {
                        return (
                          <Link
                            className="player-icon"
                            to={`/player/${this.state.ThirdVideo.id}`}
                          >
                            <img src={iconPlayer} />
                          </Link>
                        );
                      }
                    })()}
                    <div className="action-barre d-flex justify-content-between">
                      <a
                        onClick={(e) =>
                          this.handleClick(this.state.ThirdVideo.id, e)
                        }
                        href="#"
                        className="like-button text-decoration-none"
                        id={this.state.ThirdVideo.id}
                      >
                        <div className="like-animation">
                          <img src={iconLikeActive} />
                          <img src={iconLikeDefault} />
                        </div>
                        <span>{this.state.ThirdVideo.likes}</span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) =>
                          this.openShareModal(this.state.ThirdVideo.id, e)
                        }
                        className="share-button text-decoration-none"
                      >
                        <img src={JSONIMG.picto.share.img.path} />
                      </a>
                    </div>
                    <Link to={`/player/${this.state.ThirdVideo.id}`}>
                      <div></div>
                      <img
                        src={
                          `${process.env.REACT_APP_API_URL}/element/` +
                          this.state.ThirdVideo.url
                        }
                      />
                    </Link>
                  </div>
                  <h1>#3 {JSON.main.trend}</h1>
                  <p className="mt-1">
                    {this.state.ThirdVideo.city} -{" "}
                    {this.state.ThirdVideo.country}
                  </p>
                </div>
                {/* ---> TOP 4 <--- */}
                <div className="video-container col-sm-12 col-lg-4" id="top4">
                  <div>
                    {(() => {
                      if (
                        this.state.FourthVideo.url.split(".")[1] == "mp4" ||
                        this.state.FourthVideo.url.split(".")[1] == "webm"
                      ) {
                        return (
                          <Link
                            className="player-icon"
                            to={`/player/${this.state.FourthVideo.url}`}
                          >
                            <img src={iconPlayer} />
                          </Link>
                        );
                      }
                    })()}
                    <div className="action-barre d-flex justify-content-between">
                      <a
                        onClick={(e) =>
                          this.handleClick(this.state.FourthVideo.id, e)
                        }
                        href="#"
                        className="like-button text-decoration-none"
                        id={this.state.FourthVideo.id}
                      >
                        <div className="like-animation">
                          <img src={iconLikeActive} />
                          <img src={iconLikeDefault} />
                        </div>
                        <span>{this.state.FourthVideo.likes}</span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) =>
                          this.openShareModal(this.state.FourthVideo.id, e)
                        }
                        className="share-button text-decoration-none"
                      >
                        <img src={JSONIMG.picto.share.img.path} />
                      </a>
                    </div>
                    <Link to={`/player/${this.state.FourthVideo.id}`}>
                      <div></div>
                      <img
                        src={
                          `${process.env.REACT_APP_API_URL}/element/` +
                          this.state.FourthVideo.url
                        }
                      />
                    </Link>
                  </div>
                  <h1>#4 {JSON.main.trend}</h1>
                  <p className="mt-1">
                    {this.state.FourthVideo.city} -{" "}
                    {this.state.FourthVideo.country}
                  </p>
                </div>
              </div>
              <Pagination
                openShareModal={(url, e) => this.openShareModal(url, e)}
              ></Pagination>
            </section>
            {/* <Interview></Interview> */}
            <Participer></Participer>
          </main>
          <Footer></Footer>
        </div>
      </>
    );
  }
}
