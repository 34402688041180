import React, { Component, useContext, useEffect } from "react";
import { JsonContext } from "../context/json.context";
import textJson from "../style/text";

export default function Navbar() {
  const [language, setLanguage] = React.useState("en");
  const { JSON } = useContext(JsonContext);

  //initialize new state
  useEffect(() => {
    setLanguage(localStorage.getItem("av-2022-lang"));
  }, []);

  //update state when language is changed
  const handleChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("av-2022-lang", e.target.value);
    //reload page
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-home navbar-expand-lg navbar-dark bg-light navbar-absolute">
      {JSON && (
        <>
          <a className="navbar-brand" href="/">
            <img src={JSON.logo.white.path} alt={JSON.logo.white.alt} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse navbar-axione d-lg-flex justify-content-lg-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link hover:underline" href="#videos">
                  {JSON.nav.photos}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link hover:underline" href="#participer">
                  {JSON.nav.rules}
                </a>
              </li>
              <li className="nav-lang-choice-container">
                <select
                  defaultValue={language}
                  onChange={(e) => {
                    console.log(e.target.value);
                    handleChange(e);
                  }}
                >
                  <option value="fr">FR</option>
                  <option value="en">EN</option>
                </select>
              </li>
              <li className="nav-item"></li>
            </ul>
          </div>
        </>
      )}
    </nav>
  );
}
