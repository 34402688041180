import React, { Component } from "react";
import videoCodir from "../style/video/interview.mp4";

export default class CodirVideo extends Component {
  render() {
    return (
      <div
        className="modal fade"
        id="ModalCodir"
        tabIndex="-1"
        aria-labelledby="ModalCodir"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="d-flex">
              <a className="close-modal-upload" data-dismiss="modal" href="#">
                <span>&times;</span>
                <span>Retour</span>
              </a>
              <video controls muted autoPlay className="col-12 p-0">
                <source src={videoCodir} type="video/mp4"></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
