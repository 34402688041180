import React, { Component } from "react";
import $ from "jquery";
import textJson from "../style/text";

/* import iconModifier from "../style/img/icons/icon_modifier.svg";
import cloundUpload from "../style/img/elements/Cloud_upload.svg"; */

export default class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "ou cliquez pour sélectionner votre fichier",
      file: "",
      title: "",
      firstName: "",
      lastName: "",
      eMail: "",
    };
  }
  render() {
    let JSON = "";
    let JSONIMG = textJson[0];
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }
    return (
      <div
        className="modal fade"
        id="ModalUploadSuccess"
        tabIndex="-1"
        aria-labelledby="Fichiet téléchargé avec succès !"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="text-center" id="succesuploadcontainer">
              <h3 className="p-5 text-center">{JSON.upload.success.title}</h3>
              {/* <p>N'hésitez pas à liker et partager<br />les vœux de l'équipe</p> */}
              <div className="btn-axione-animation btn-succes-upload">
                <a data-dismiss="modal" href="#" className="nav-link">
                  {JSON.upload.success.button}
                </a>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
