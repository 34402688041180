/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import textJson from "../style/text";
import SocialMediaFigure from "./SocialMediaFigure";

export default function ShareModal({ isOpen, path = "", onClose = () => {} }) {
  const url = `${window.location.origin}/?${path}`;
  const [animOnClose, setAnimOnClose] = useState(false);

  useEffect(() => {}, []);

  let JSON = "";
  let JSONIMG = textJson[0];
  const language = localStorage.getItem("av-2022-lang");
  if (language == "fr") {
    JSON = textJson[0].fr;
  } else {
    JSON = textJson[0].en;
  }

  const startClose = () => {
    setAnimOnClose(true);
    setTimeout(() => {
      onClose();
      setAnimOnClose(false);
    }, 300);
  };
  // convert link to url
  const urlToLink = (url) => {
    let urlLink = url;
    urlLink = urlLink.replace(/ /g, "%20");
    urlLink = urlLink.replace(/\//g, "%2F");
    urlLink = urlLink.replace(/:/g, "%3A");
    urlLink = urlLink.replace(/\?/g, "%3F");
    urlLink = urlLink.replace(/=/g, "%3D");
    urlLink = urlLink.replace(/&/g, "%26");
    urlLink = urlLink.replace(/#/g, "%23");
    return urlLink;
  };

  return (
    <>
      <div className={`${isOpen === false ? "hidden" : "shareModal"}`}>
        <div
          className={`${
            animOnClose ? "fadeOut" : "fadeIn"
          } absolute h-screen w-screen`}
          onClick={() => startClose()}
        />

        <div
          className={`${
            animOnClose ? "fadeOutUp" : "fadeInUp"
          } social-modal  z-10 rounded-lg`}
        >
          <div className="return" onClick={() => startClose()}>
            <img
              alt={JSON.social.cross.img.alt}
              src={JSON.social.cross.img.path}
            />
            <p>{JSON.social.return}</p>
          </div>
          <div className="social-modal-content ">
            {/* FACEBOOK */}
            <SocialMediaFigure
              onClickFunction={() => {
                // const urlToFormate = url;
                // //replace / with %2F, : with %3A, ? with %3F, & with %26
                // const urlToFormate2 = urlToFormate.replace(/\//g, "%2F");
                // const urlToFormate3 = urlToFormate2.replace(/:/g, "%3A");
                // const urlToFormate4 = urlToFormate3.replace(/\?/g, "%3F");
                // const urlToFormate5 = urlToFormate4.replace(/&/g, "%26");
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=${url}`,
                  "_blank"
                );
              }}
              alt={JSON.social.facebook.img.alt}
              src={JSON.social.facebook.img.path}
            >
              {JSON.social.facebook.img.name}
            </SocialMediaFigure>

            {/* INSTAGRAM */}
            {/* <SocialMediaFigure
              alt={JSON.social.instagram.img.alt}
              src={JSON.social.instagram.img.path}
            >
              {JSON.social.instagram.img.name}
            </SocialMediaFigure> */}

            {/* MAIL */}
            <SocialMediaFigure
              onClickFunction={() => {
                window.open(
                  `mailto:?subject=Challenge%20Axione%202022&body=${urlToLink(
                    url
                  )}`,
                  "_blank"
                );
              }}
              alt={JSON.social.mail.img.alt}
              src={JSON.social.mail.img.path}
            >
              {JSON.social.mail.img.name}
            </SocialMediaFigure>

            {/* LINKEDIN */}
            <SocialMediaFigure
              onClickFunction={() => {
                const urlToFormate = url;
                //replace / with %2F, : with %3A, ? with %3F, & with %26
                const urlToFormate2 = urlToFormate.replace(/\//g, "%2F");
                const urlToFormate3 = urlToFormate2.replace(/:/g, "%3A");
                const urlToFormate4 = urlToFormate3.replace(/\?/g, "%3F");
                const urlToFormate5 = urlToFormate4.replace(/&/g, "%26");

                window.open(
                  `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
                  "_blank"
                );
              }}
              alt={JSON.social.linkedin.img.alt}
              src={JSON.social.linkedin.img.path}
            >
              {JSON.social.linkedin.img.name}
            </SocialMediaFigure>
          </div>
        </div>
      </div>
    </>
  );
}
