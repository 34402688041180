/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import iconPlayer from "../style/img/icons/play.svg";
import interviewPicture from "../style/video/interview-picture.png";
import textJson from "../style/text";
import Participer from "./Participer";

export default class Interview extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let JSON = "";
    let JSONIMG = textJson[0];
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }
    return (
      <section id="interview">
        <img id="womanComputer" src={JSONIMG.images.womenComputer.img.path} />
        <img id="sunGlasses" src={JSONIMG.images.sunGlasses} />

        <div className="container d-flex align-items-center justify-content-center">
          <div className="col-12 col-lg-6 ">
            <h1>{JSON.participate.title}</h1>
            <div className="col-12 d-flex">
              <img
                src={JSON.participate.step1.img.path}
                className="pr-3"
                alt=""
              />
              <div className="col-12 col-md-6">
                <img src={JSON.participate.step1.picto.path} alt="" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: JSON.participate.step1.text,
                  }}
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-md-end">
              <img
                src={JSON.participate.step2.img.path}
                className="pr-3"
                alt=""
              />
              <div className="col-12 col-md-5">
                <img src={JSON.participate.step2.picto.path} alt="" />
                <p>
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ModalUpload"
                    dangerouslySetInnerHTML={{
                      __html: JSON.participate.step2.text,
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="col-12 d-flex ml-md-3">
              <img src={JSON.participate.step3.img.path} className="pr-3" />
              <div className="col-12 col-md-5">
                <img src={JSON.participate.step3.picto.path} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: JSON.participate.step3.text,
                  }}
                />
              </div>
            </div>
            {/* <h2>{JSON.participate.help}</h2> */}
            <p className="btn-guide">
              <div className="btn-axione-animation btn-guide-div">
                <a href="./axione-guide.pdf" download className="nav-link">
                  {JSON.participate.button}
                </a>
                <div></div>
              </div>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
