import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import iconModifier from "../style/img/icons/icon_modifier.svg";
import cloundUpload from "../style/img/elements/Cloud_upload.svg";
import textJson from "../style/text";
export default class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "ou cliquez pour sélectionner votre fichier",
      file: "",
      title: "",
      firstName: "",
      lastName: "",
      eMail: "",
      city: "",
      country: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = (e) => {
    try {
      this.setState({
        fileName: e.target.files[0].name,
        file: e.target.files[0],
      });
    } catch (err) {
      console.error("Error catch ! : ", err);
    }
  };

  putLoader() {
    $("#buttonUpload").html(`
    <div class="spinner-border text-light" role="status">
    <span class="sr-only">Loading...</span>
    </div>
    `);
  }
  putDesabled() {
    $("#buttonUpload").addClass("disabled");
  }
  putReset() {
    $("#buttonUpload").html(`Envoyer`);
    $("#buttonUpload").removeClass("disabled");
  }

  errorUploadMessage(message) {
    $("#messageOfUpload").html(message);
    //$("#messageOfUpload").removeClass("text-center").addClass("text-danger");
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.putDesabled();
    this.putLoader();
    //check if the file is not type of png, jpg or jpeg return error
    if (
      this.state.file.type != "image/png" &&
      this.state.file.type != "image/jpeg" &&
      this.state.file.type != "image/jpg"
    ) {
      this.putReset();
      this.errorUploadMessage(
        `${
          textJson[0][localStorage.getItem("av-2022-lang")]?.upload.error.type
        }`
      );
      return;
    }
    //check if the file is not bigger than 5Mo return error
    console.log("FILE -> ", this.state.file);
    if (this.state.file.size > 5000000) {
      this.putReset();
      //console.log(textJson[0][localStorage.getItem("av-2022-lang")].upload);
      this.errorUploadMessage(
        `${
          textJson[0][localStorage.getItem("av-2022-lang")]?.upload?.error?.size
        }`
      );
      return;
    }

    const data = new FormData();
    data.append("firstname", this.state.firstName);
    data.append("lastname", this.state.lastName);
    data.append("email", this.state.eMail);
    data.append("city", this.state.city);
    data.append("country", this.state.country);
    data.append("file", this.state.file);
    // get our form data out of state
    axios
      .post(`${process.env.REACT_APP_API_URL}/element/upload`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        $("#buttonUpload").html(`Terminé !`);
        //.$('#ModalUpload').modal('hide');
        // $( "#ModalUpload" ).fadeOut("fast");
        //$('#ModalUploadSuccess').modal('show');
      })
      .catch((error) => {
        console.log(error);
        this.putReset();
        this.errorUploadMessage(`
          Une erreur est survenue, veuillez réessayer.
          `);
      });
  };

  componentDidMount() {
    const language = localStorage.getItem("av-2022-lang");
    if (language === "fr") {
      this.setState({
        fileName: "ou cliquez pour sélectionner votre fichier",
      });
    } else {
      this.setState({
        fileName: "ou click to select your file",
      });
    }
  }

  render() {
    let JSON = "";
    let JSONIMG = textJson[0];
    const language = localStorage.getItem("av-2022-lang");
    // const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }

    return (
      <div
        className="modal fade"
        id="ModalUpload"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <form onSubmit={this.onSubmit}>
              <div className="d-flex video-upload-section align-items-center">
                <div className="upload-section">
                  <a
                    className="close-modal-upload"
                    data-dismiss="modal"
                    href="#"
                  >
                    <div>
                      <span className="mr-2 text-2xl">&times;</span>
                      <span>{JSON.upload.back}</span>
                    </div>
                  </a>
                  <div className="row">
                    <div className="col-6 pl-0 pr-2">
                      <input
                        type="text"
                        name="firstName"
                        placeholder={JSON.upload.placeholder.firstname}
                        className="form-control-custom"
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col-6 pr-0 pl-2">
                      <input
                        type="text"
                        name="lastName"
                        placeholder={JSON.upload.placeholder.name}
                        className="form-control-custom"
                        onChange={this.onChange}
                        required
                      />
                    </div>

                    <div className="col-12 pt-2 pr-0 pl-0">
                      <input
                        type="email"
                        name="eMail"
                        className="form-control-custom"
                        placeholder={JSON.upload.placeholder.email}
                        onChange={this.onChange}
                        required
                      />
                    </div>

                    <div className="col-6 pt-2 pr-2 pl-0">
                      <input
                        type="text"
                        name="city"
                        className="form-control-custom"
                        placeholder={JSON.upload.placeholder.city}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col-6 pt-2 pr-0 pl-2">
                      <input
                        type="text"
                        name="country"
                        className="form-control-custom"
                        placeholder={JSON.upload.placeholder.country}
                        onChange={this.onChange}
                        required
                      />
                    </div>

                    <div className="dropfile-zone mt-3">
                      <p className="flex flex-col items-center">
                        <img
                          src={JSON.upload.img.path}
                          alt={JSON.upload.img.alt}
                        />

                        <p className="color-[#ffd1a8]">
                          {JSON.upload.dragndrop} <br />
                          <span className="text-xs">
                            (.jpg, .jpeg, .png)
                          </span>{" "}
                          <br />
                          {JSON.upload.maxsize}
                        </p>
                        <label htmlFor="customFile">
                          {this.state.fileName}
                        </label>
                      </p>
                      <div className="custom-file">
                        <input
                          placeholder="Un fichier"
                          type="file"
                          name="file"
                          className="custom-file-input"
                          id="inputGroupFile02"
                          onChange={this.onChangeFile}
                          accept=".png, .jpeg, .jpg"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <span className="absolute left-2 bottom-2 text-xs italic text-white opacity-70">
                    *{JSON.upload.asterisk}
                  </span>
                </div>
                <div className="submit-section d-flex flex-column">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: JSON.upload.description1,
                    }}
                  ></p>
                  <a href="./axione.pdf" download className="sous-title">
                    {JSON.upload.help}
                  </a>
                  <div className="form-check pt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="check4"
                      required
                    />
                    <label className="form-check-label" htmlFor="check4">
                      {JSON.upload.rules3}
                    </label>
                  </div>
                  {/* <div className="form-check pt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="check1"
                      required
                    />
                    <label className="form-check-label" htmlFor="check1">
                      {JSON.upload.data}
                    </label>
                  </div> */}
                  <div className="form-check pt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="check2"
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="check2"
                      dangerouslySetInnerHTML={{
                        __html: JSON.upload.data + JSON.upload.rules,
                      }}
                    ></label>
                  </div>
                  <div className="form-check pt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="check3"
                      required
                    />
                    <label className="form-check-label" htmlFor="check3">
                      {JSON.upload.rules2}
                    </label>
                  </div>
                  <p
                    className="mt-3 w-full text-center underline"
                    id="messageOfUpload"
                  ></p>
                  <button
                    id="buttonUpload"
                    type="submit"
                    className="btn-upload-video"
                  >
                    {JSON.upload.button}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
