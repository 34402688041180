import React, { Component } from "react";
import footerLogo from "../style/img/elements/Axionne_footer.svg";
import logodark from "../style/img/logos/logodark.svg";
import logoInstagram from "../style/img/icons/Insta_footer.svg";
import logoLinkedin from "../style/img/icons/linkedin_footer.svg";
import logoYoutube from "../style/img/icons/Youtube_footer.svg";
import logoTweeter from "../style/img/icons/tweeter_footer.svg";
import textJson from "../style/text";

export default class Footer extends Component {
  render() {
    let JSON = "";
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }
    return (
      <footer className="d-flex align-items-center flex-column flex-md-row">
        <div className="col-12 col-md-3">
          <a href="#" className="flex justify-center sm:block">
            <img alt="unknown" src={JSON.logo.animons.path} />
          </a>
        </div>
        <div className="col-12 col-md-5 mt-md-0 mt-4">
          <ul>
            <li>
              <a href="/mentions-legales">{JSON.footer.legalNotices}</a>
            </li>
            <li>
              <a href="/confidentialite">{JSON.footer.privacy}</a>
            </li>
            <li>
              <a href="https://agencerjs.com/" rel="noreferrer" target="_blank">
                {JSON.footer.copyright}
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-4 mt-md-0 mt-4">
          <div className="d-flex flex-column align-items-center justify-content-center justify-content-md-end mt-4 ">
            <a
              className="logo-axione-footer !justify-center lg:!justify-end"
              href="https://www.axione.com/"
              rel="noreferrer"
              target="blank_"
            >
              <img alt="unknown" src={JSON.logo.colored.path} />
            </a>
            <div className="d-flex align-items-center col-12 justify-content-center justify-content-md-end p-0">
              <p>{JSON.social.follow}</p>
              <a
                href={JSON.social.linkedin.url}
                rel="noreferrer"
                target="blank_"
              >
                <img alt="unknown" src={JSON.social.linkedin.img.path} />
              </a>
              <a
                href={JSON.social.youtube.url}
                rel="noreferrer"
                target="blank_"
              >
                <img alt="unknown" src={JSON.social.youtube.img.path} />
              </a>
              <a
                href={JSON.social.twitter.url}
                rel="noreferrer"
                target="blank_"
              >
                <img alt="unknown" src={JSON.social.twitter.img.path} />
              </a>
              <a
                href={JSON.social.instagram.url}
                rel="noreferrer"
                target="blank_"
              >
                <img alt="unknown" src={JSON.social.instagram.img.path} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
