/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useRef } from "react";
import SimpleParallax from "simple-parallax-js";
import textJson from "../style/text";

export default function Participer() {
  const sunGlassesRef = useRef(null);

  let JSON = "";
  let JSONIMG = textJson[0];
  const language = localStorage.getItem("av-2022-lang");
  if (language == "fr") {
    JSON = textJson[0].fr;
  } else {
    JSON = textJson[0].en;
  }

  useEffect(() => {
    new SimpleParallax(sunGlassesRef.current, {
      delay: 0.6,
      scale: 1.5,
      orientation: "down",
      transition: "cubic-bezier(0,0,0,1)",
    });
  }, []);

  return (
    <section id="participer" className="d-flex justify-content-center">
      <img id="womanComputer" src={JSONIMG.images.womenComputer.img.path} />
      <img
        id="sunGlasses"
        ref={sunGlassesRef}
        src={JSONIMG.images.sunGlasses}
      />
      <div className="col-12 col-lg-6 participer__container">
        <h1>{JSON.participate.title}</h1>
        <div className="col-12 d-flex">
          <img
            src={JSON.participate.step1.img.path}
            className="pr-3"
            style={{
              marginTop: -40,
            }}
            alt=""
          />
          <div className="col-10 col-md-12">
            <img src={JSON.participate.step1.picto.path} alt="" />
            <p
              dangerouslySetInnerHTML={{
                __html: JSON.participate.step1.text,
              }}
            />
            <h6
              dangerouslySetInnerHTML={{
                __html: JSON.participate.step1.subtext,
              }}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-md-end align-items-end">
          <div className="col-7 col-md-12">
            {/* <img src={JSON.participate.step2.picto.path} alt="" /> */}
            <p className="text-right">
              <a
                href="#"
                data-toggle="modal"
                data-target="#ModalUpload"
                dangerouslySetInnerHTML={{
                  __html: JSON.participate.step2.text,
                }}
              />
            </p>
          </div>
          <img src={JSON.participate.step2.img.path} className="pr-3" alt="" />
        </div>
        <div className="col-12 d-flex ml-md-3">
          <img src={JSON.participate.step3.img.path} className="pr-3" />
          <div className="col-8 col-md-12">
            <img
              src={JSON.participate.step3.picto.path}
              style={{
                marginLeft: -40,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: JSON.participate.step3.text,
              }}
            />
          </div>
        </div>
        {/* <h2>{JSON.participate.help}</h2> */}
        <p className="btn-guide">
          <div className="btn-axione-animation btn-guide-div">
            <a
              href={
                language == "fr"
                  ? "./axione-guide.pdf"
                  : "./axione-guide-en.pdf"
              }
              download
              className="nav-link flex items-center lg:block"
            >
              {JSON.participate.button}
            </a>
            <div></div>
          </div>
        </p>
      </div>
    </section>
  );
}
