import React, { createContext, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Home from "./component/Home";
import Player from "./component/Player";
import Codir from "./component/Codir";
import ErrorPage from "./component/ErrorPage";
import Upload from "./component/Upload";
import UploadSuccess from "./component/UploadSuccess";
import Navbar from "./component/Navbar";
import MentionsLegales from "./component/MentionsLegales";
import Confidentialite from "./component/Confidentialite";
import NavbarVideo from "./component/NavbarVideo";
import CodirVideo from "./component/CodirVideo";
import "./App.css";
import textJson from "./style/text";
import { JsonContext } from "./context/json.context";

function App() {
  const [language, setLanguage] = React.useState("en");
  const [json, setJson] = React.useState("");

  useEffect(() => {
    if (!localStorage.getItem("liked-element-ax")) {
      localStorage.setItem("liked-element-ax", JSON.stringify([]));
    }
    const userLanguage =
      navigator.language || navigator.userLanguage || "en-US";
    if (!localStorage.getItem("av-2022-lang")) {
      if (userLanguage.includes("fr")) {
        localStorage.setItem("av-2022-lang", "fr");
        setLanguage("fr");
      } else {
        localStorage.setItem("av-2022-lang", "en");
        setLanguage("en");
      }
    } else {
      setLanguage(localStorage.getItem("av-2022-lang"));
    }
  }, []);

  useEffect(() => {
    setJson(textJson[0][language]);
  }, [language]);

  return (
    <BrowserRouter forceRefresh="true">
      <JsonContext.Provider value={{ JSON: json }}>
        <Router>
          <Switch>
            <Route exact path="/" component={Navbar}></Route>
            <Route path="/player/:id" component={NavbarVideo}></Route>
            {/* <Route path="/voeux-codir" component={NavbarVideo}></Route> */}
            <Route path="/*" component={NavbarVideo}></Route>
          </Switch>
          <Upload></Upload>
          <UploadSuccess></UploadSuccess>
          {/* <CodirVideo></CodirVideo> */}
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/upload" component={Upload}></Route>
            <Route path="/player/:id" component={Player}></Route>
            <Route path="/voeux-codir" component={Codir}></Route>
            <Route
              exact
              path="/mentions-legales"
              component={MentionsLegales}
            ></Route>
            <Route
              exact
              path="/confidentialite"
              component={Confidentialite}
            ></Route>
            <Route path="/*" component={ErrorPage}></Route>
          </Switch>
        </Router>
      </JsonContext.Provider>
    </BrowserRouter>
  );
}
export default App;
