import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import moment from "moment";

import iconLikeDefault from "../style/img/icons/like_standby_player.svg";
import iconLikeActive from "../style/img/icons/like_active.svg";
import logoFacebook from "../style/img/logos/facebook.svg";
import logoInstagram from "../style/img/logos/instagram.svg";
import logoEmail from "../style/img/logos/email.svg";
import logoLinkedin from "../style/img/logos/linkedin.svg";
import textJson from "../style/text";
export default class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoId: this.props.match.params.id,
      videoData: {},
      loading: true,
    };
  }

  hopLike(id) {
    if ($("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").removeClass("like-active");
      let like = Number($("#" + id + " > span").text()) - 1;
      $("#" + id + " > span").html(like);
    } else if (!$("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").addClass("like-active");
      let like = Number($("#" + id + " > span").text()) + 1;
      $("#" + id + " > span").html(like);
    }
  }

  sendLike(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/like/${id}`)
      .then(() => {
        localStorage.setItem(id, JSON.stringify(id));
      });
  }
  sendUnlike(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/dislike/${id}`)
      .then(() => {
        localStorage.removeItem(id);
      });
  }

  handleClick(id, e) {
    e.preventDefault();
    return;
    //get local storage file
    let sl = JSON.parse(localStorage.getItem(id));

    //check if sl file exist
    if (!sl) {
      //sl doesn't exist
      this.sendLike(id, sl);
    } else {
      //sl exist, check if id is inside
      this.sendUnlike(id);
    }
    this.hopLike(id);
  }

  async componentDidMount() {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/element/data/${this.state.videoId}`
      );
      const data = await res.json();
      localStorage.setItem("share-modal-ax", window.location.href);
      this.setState({ videoData: data, loading: false });
      this.addView(this.state.videoId);
    } catch (error) {
      console.log(error);
      //redirect to 404
      this.props.history.push("/404");
    }
  }

  addView(url) {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/element/views/${this.state.videoId}`
      )
      .then(() => {
        console.log("ready.");
      });
  }

  render() {
    if (this.state.loading === true) {
      return <div>Loading</div>;
    }

    let JSON = "";
    let JSONIMG = textJson[0];
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }
    const video_info = this.state.videoData;
    //        console.log(video_info.id);

    const servUrl = JSONIMG.share.server;

    const url = `${window.location.origin}/?${video_info.id}`;

    const urlFB = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const urlMail = "mailto:?subject=" + "&body=ChallengeAxione2022" + url;
    const urlLkd = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
    // const urlFB =
    //   JSONIMG.share.facebook +
    //   encodeURI(servUrl + video_info.url) +
    //   "%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse";
    // const urlMail = JSONIMG.share.mail + encodeURI(servUrl + video_info.url);
    // const urlLkd = JSONIMG.share.linkedin + encodeURI(servUrl + video_info.url);
    // const urlInsta = JSONIMG.share.instagram;
    // const filetype = video_info.url.split(".");

    return (
      <div className="image-container">
        <div className="">
          <img
            src={`${process.env.REACT_APP_API_URL}/element/${video_info.url}`}
            className="w-100"
          />
          <div className="action-barre-player d-flex justify-content-between align-items-center">
            <div className="col-3 d-flex align-items-center">
              <a
                href="#"
                onClick={(e) => this.handleClick(video_info.id, e)}
                id={video_info.id}
                className="like-button text-decoration-none"
              >
                <div className="like-animation">
                  <img src={iconLikeActive} />
                  <img src={iconLikeDefault} />
                </div>
                <span>{video_info.likes}</span>
              </a>
            </div>
            <div className="col-9 d-flex align-items-center justify-content-end share-section">
              <p>Partager: </p>
              <a href={urlFB}>
                <img
                  style={{ width: "20px" }}
                  src={JSON.social.facebook.img.path}
                />
              </a>
              {/* <a href={urlInsta}>
                <img
                  style={{ width: "20px" }}
                  src={JSON.social.smallInsta.img.path}
                />
              </a> */}
              <a href={urlMail}>
                <img
                  style={{ width: "20px" }}
                  src={JSON.social.mail.img.path}
                />
              </a>
              <a href={urlLkd}>
                <img
                  style={{ width: "20px" }}
                  src={JSON.social.linkedin.img.path}
                />
              </a>
              <p>{video_info.views} 👀 </p>
            </div>
          </div>
          <div className="info-barre-player d-flex justify-content-between align-items-center">
            <div className="col-6">
              <p>
                {video_info.User.firstname} {video_info.User.lastname}
              </p>
            </div>
            <div className="col-6">
              <p className="text-right">
                {video_info.city} • {video_info.country}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
