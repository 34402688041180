import React, { Component } from "react";

export default class ItemCurrentScroll extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="item-current-scroll item-default-scroll">
        <div>
          <div className="itemhome" id="active-item-current-scroll"></div>
          <div className="itemvideo"></div>
          <div className="itemparticiper"></div>
        </div>
      </div>
    );
  }
}
