import React, { Component } from "react";
import textJson from "../style/text";
export default class ErrorPage extends Component {
  render() {
    let JSON = "";
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }

    return (
      <div className="error-container d-flex justify-content-center align-items-center">
        <div className="flex w-2/3 flex-col items-center">
          <img alt={JSON.error404.img.alt} src={JSON.error404.img.path} />
          <h1 dangerouslySetInnerHTML={{ __html: JSON.error404.description }} />
          <a href="/">{JSON.error404.button}</a>
        </div>
      </div>
    );
  }
}
