/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import axios from "axios";
import logodark from "../style/img/logos/logodark.svg";

import iconLikeDefault from "../style/img/icons/like_standby.svg";
import iconLikeActive from "../style/img/icons/like_active.svg";
/* import iconShare from "../style/img/icons/share.svg"; */
import iconPlayer from "../style/img/icons/play.svg";
import { Link } from "react-router-dom";
import $, { merge } from "jquery";
import textJson from "../style/text";
import ShareModal from "./ShareModal";

export default class PaginationWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activePage: 1,
      collection: [],
      pageOfItems: [],
      pages: 0,

      selectedPostUrl: "",
      shareModalIsOpen: false,
    };
  }

  hopLike(id) {
    if ($("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").removeClass("like-active");
      let like = Number($("#" + id + " > span").text()) - 1;
      $("#" + id + " > span").html(like);
    } else if (!$("#" + id + " > .like-animation").hasClass("like-active")) {
      $("#" + id + " > div").addClass("like-active");
      let like = Number($("#" + id + " > span").text()) + 1;
      $("#" + id + " > span").html(like);
    }
  }

  sendLike(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/like/${id}`)
      .then(() => {
        const alreadyLiked = JSON.parse(
          localStorage.getItem("liked-element-ax")
        );
        if (!alreadyLiked) {
          localStorage.setItem("liked-element-ax", JSON.stringify([id]));
        }
        if (alreadyLiked) {
          let newLiked = alreadyLiked;
          newLiked.push(id);
          localStorage.setItem("liked-element-ax", JSON.stringify(newLiked));
        }
      });
  }
  sendUnlike(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/element/dislike/${id}`)
      .then(() => {
        const alreadyLiked = JSON.parse(
          localStorage.getItem("liked-element-ax")
        );
        if (alreadyLiked) {
          let newLiked = alreadyLiked;
          newLiked.splice(newLiked.indexOf(id), 1);
          localStorage.setItem("liked-element-ax", JSON.stringify(newLiked));
        }
      });
  }

  handleClick(id, e) {
    e.preventDefault();
    return;
    $("#" + id + "").addClass("disabled_like");

    //check if user already liked this element
    const alreadyLiked = JSON.parse(localStorage.getItem("liked-element-ax"));
    if (alreadyLiked) {
      if (alreadyLiked.includes(id)) {
        this.sendUnlike(id);
        console.log("unlike");
        $("#" + id + "").removeClass("like-active");
      } else {
        this.sendLike(id);
        $("#" + id + "").addClass("like-active");
      }
    }

    this.hopLike(id);
    setTimeout(() => {
      $("#" + id + "").removeClass("disabled_like");
    }, 1000);
  }

  componentDidMount() {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/element`).then((res) => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        if (res.data.message != "No such videos in data table.") {
          //remove 4th videos from array
          res.data.splice(res.data[0], 1);
          res.data.splice(res.data[1], 1);
          res.data.splice(res.data[2], 1);
          res.data.splice(res.data[3], 1);

          this.setState({
            collection: res.data,
            pages: Math.ceil(res.data.length / 8),
            loading: false,
          });
          this.handlePageChange(1);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  handlePageChange(pageNumber) {
    //    console.log(`active page is ${pageNumber}`);
    let tab = [];
    let offset = Math.ceil((pageNumber - 1) * this.state.pages);
    for (let i = 0; i < 8; ++i) {
      if (this.state.collection[offset + i] != undefined)
        tab.push(this.state.collection[offset + i]);
    }
    this.setState({
      activePage: pageNumber,
      pageOfItems: tab,
    });
  }
  isImagePlayer(url) {
    const filetype = url.split(".");
    let iconOfType;
    if (filetype[1] == "png" || filetype[1] == "jpg" || filetype[1] == "jpeg") {
      iconOfType = ``;
    }
  }

  openShareModal(url, event) {
    event.preventDefault();
    this.setState({
      selectedPostUrl: "/player/" + url,
      shareModalIsOpen: true,
    });
  }

  render() {
    let JSON = "";
    let JSONIMG = textJson[0];
    const language = localStorage.getItem("av-2022-lang");
    if (language == "fr") {
      JSON = textJson[0].fr;
    } else {
      JSON = textJson[0].en;
    }
    if (this.state.loading === true) {
      return (
        <div className="loader-axione d-flex justify-content-center align-items-center flex-column">
          <a href="#" data-toggle="modal" data-target="#ModalUpload">
            <img src={logodark} className="logo-loading" />
          </a>
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <>
        <section>
          <div className="d-flex container flex-wrap">
            {this.state.pageOfItems.map((video) => (
              <div
                key={video.id}
                className="video-container col-sm-12 col-lg-3"
              >
                <div>
                  {(() => {
                    if (
                      video.url.split(".")[1] == "mp4" ||
                      video.url.split(".")[1] == "webm"
                    ) {
                      return (
                        <Link
                          className="player-icon"
                          to={`/player/${video.id}`}
                        >
                          <img src={iconPlayer} />
                        </Link>
                      );
                    }
                  })()}
                  <div className="action-barre d-flex justify-content-between">
                    <a
                      onClick={(e) => this.handleClick(video.id, e)}
                      href="#"
                      className="like-button text-decoration-none"
                      id={video.id}
                    >
                      <div className="like-animation">
                        <img src={iconLikeActive} />
                        <img src={iconLikeDefault} />
                      </div>
                      <span>{video.likes}</span>
                    </a>

                    <a
                      href="#"
                      onClick={(e) => this.props.openShareModal(video.id, e)}
                      className="share-button text-decoration-none"
                    >
                      <img src={JSONIMG.picto.share.img.path} />
                    </a>
                  </div>
                  <Link to={`/player/${video.id}`}>
                    <div></div>
                    <img
                      src={
                        `${process.env.REACT_APP_API_URL}/element/` + video.url
                      }
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div id="pagination_wrapper">
            <nav className="mt-4">
              <Pagination
                items={this.state.pageOfItems}
                activePage={this.state.activePage}
                itemsCountPerPage={8}
                totalItemsCount={this.state.collection.length + 5}
                pageRangeDisplayed={4}
                onChange={this.handlePageChange.bind(this)}
              />
            </nav>
          </div>
        </section>
      </>
    );
  }
}
